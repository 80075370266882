import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_community_home = _resolveComponent("community-home")
  const _component_office_home = _resolveComponent("office-home")
  const _component_old_community_home = _resolveComponent("old-community-home")

  return (_openBlock(), _createBlock("div", null, [
    (_ctx.projectType === 'community'&& _ctx.isNew )
      ? (_openBlock(), _createBlock(_component_community_home, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.projectType === 'office')
      ? (_openBlock(), _createBlock(_component_office_home, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.projectType === 'community' && !_ctx.isNew)
      ? (_openBlock(), _createBlock(_component_old_community_home, { key: 2 }))
      : _createCommentVNode("", true)
  ]))
}