
import { defineComponent } from 'vue';
import { community } from '@/data';
import communityHome from './commuinty-home.vue';
import officeHome from './office-home.vue';
import OldCommunityHome from './old-community-home.vue';

export default defineComponent({
    components: {
        communityHome,
        officeHome,
        OldCommunityHome
    },
    setup() {
        const projectType = community.ProjectType;
        const { isNew } = community;
        console.log(1);

        return {
            projectType,
            isNew
        };
    }
});
